import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import AppContext from "../../../context/AppContext.js";

const CountryDropdown = ({
                             selectedCountry,
                             selectedSubdivision,
                             onCountryChange,
                             onSubdivisionChange,
                             isSubdivision,
                             defaultCountry,
                             nextFieldRef,
                         }) => {
    const { countryData } = useContext(AppContext);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!countryData || !countryData.countries) {
            setOptions([]);
            return;
        }

        if (isSubdivision && selectedCountry) {
            const subdivisionOptions = countryData.countrySubdivisions
                .filter((sub) => sub.countryCode === selectedCountry)
                .map((sub) => ({
                    value: sub.code,
                    label: sub.subdivisionName,
                }));
            setOptions(subdivisionOptions);
        } else {
            const countryOptions = countryData.countries.map((country) => ({
                value: country.countryIso2,
                label: `${country.country} (${country.countryIso2})`,
            }));
            setOptions(countryOptions);

            // Set default country if none is selected
            if (!selectedCountry && defaultCountry && !isSubdivision) {
                onCountryChange({ country: defaultCountry });
            }
        }
    }, [countryData, selectedCountry, isSubdivision, defaultCountry, onCountryChange]);

    const handleChange = (selectedOption) => {
        if (isSubdivision) {
            onSubdivisionChange({ stateProvince: selectedOption ? selectedOption.value : '' });
        } else {
            onCountryChange({ country: selectedOption ? selectedOption.value : '' });
        }

        if (nextFieldRef && nextFieldRef.current) {
            nextFieldRef.current.focus();
        }
    };

    const selectedOption = options.find((option) => {
        if (isSubdivision) {
            return option.value === selectedSubdivision;
        } else {
            return option.value === selectedCountry;
        }
    }) || null;

    const filterOption = (option, inputValue) => {
        const label = option.label.toLowerCase();
        const value = option.value.toLowerCase();
        const input = inputValue.toLowerCase();
        return label.startsWith(input) || value.startsWith(input);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            height: '30px',
            borderColor: 'black',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '30px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#bde4ff' : 'white',
            color: 'black',
        }),
    };

    return (
        <Select
            styles={customStyles}
            value={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder={isSubdivision ? 'State/Province' : 'Country'}
            isSearchable={true}
            filterOption={filterOption}
            tabSelectsValue={true}
            onBlur={() => {
            }}
        />
    );
};

export default CountryDropdown;

import React from 'react';
import PropTypes from 'prop-types';

const AddressCardText = ({ address }) => {
    const {
        firstName,
        lastName,
        company,
        line1,
        line2,
        line3,
        city,
        stateProvince,
        postalCode,
        country,
        phone,
        notes
    } = address;

    // Function to handle address formatting for US and Canada
    const renderAddress = () => {
        const nameLine = [firstName, lastName].filter(Boolean).join(' ');
        const companyLine = company ? company : null;
        const addressLines = [line1, line2, line3].filter(Boolean).join(', ');
        const cityStateLine = [city, stateProvince].filter(Boolean).join(', ');
        const postalCountryLine = [postalCode, country].filter(Boolean).join(' ');

        return (
            <>
                {nameLine && <div>{nameLine}</div>}
                {companyLine && <div>{companyLine}</div>}
                {line1 && <div>{line1}</div>}
                {line2 && <div>{line2}</div>}
                {line3 && <div>{line3}</div>}
                {(cityStateLine || postalCountryLine) && (
                    <div>
                        {cityStateLine}{cityStateLine && postalCountryLine ? ', ' : ''}{postalCountryLine}
                    </div>
                )}
                {phone && <div>{phone}</div>}
                {notes && <div className="my-1 fst-italic">Notes: {notes}</div>}
            </>
        );
    };

    return (
        <div>
            {renderAddress() || <div>No Address Information</div>}
        </div>
    );
};

AddressCardText.propTypes = {
    address: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        company: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        line3: PropTypes.string,
        city: PropTypes.string,
        stateProvince: PropTypes.string,
        postalCode: PropTypes.string,
        country: PropTypes.string,
        phone: PropTypes.string,
        notes: PropTypes.string
    }).isRequired
};

export default AddressCardText;
import React, { useState } from 'react';
import {Input} from "reactstrap";
import PropTypes from "prop-types";

function AccountSearchInput(props) {
    const [isEnterPressed, setIsEnterPressed] = useState(false);
    const [timer, setTimer] = useState(null); // For storing the timeout ID

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setIsEnterPressed(true);
            clearTimeout(timer);
            // Perform your Enter key logic here
            console.log('Enter key pressed');
            props.triggerAccountSearchCallback({openModal: true});
        }
    };

    const handleBlur = (e) => {
        if (!isEnterPressed) {
            // Perform your onBlur logic here
            console.log('Input lost focus');
            props.triggerAccountSearchCallback({openModal: false});
        }
        // Reset the flag
        setIsEnterPressed(false);
    };

    const handleChange = (e) => {
        props.handleChange(e)

        // Clear the existing timer if the user types again within 300ms
        if (timer) {
            clearTimeout(timer);
        }

        // Set a new timer to trigger search after 300ms of no typing
        const newTimer = setTimeout(() => {
            console.log('timeout search');
                props.triggerAccountSearchCallback({openModal: false});
            }, props.autoSearchTimeoutMs ? props.autoSearchTimeoutMs : 750);

        setTimer(newTimer);
    }

    return (
        <Input
            invalid={props.invalid}
            type="text"
            className="form-control"
            placeholder="email, account #, name"
            value={props.value}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onBlur={handleBlur}
        />
    );
}

AccountSearchInput.propTypes = {
    value: PropTypes.string,
    invalid: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    triggerAccountSearchCallback: PropTypes.func.isRequired,
    autoSearchTimeoutMs: PropTypes.number
};


export default AccountSearchInput;
import React from 'react';
import PropTypes from "prop-types";
import {Input} from "reactstrap";

class LockableLineItemField extends React.Component {
    formatLockedValue() {
        if (this.props.lockedFormatter)
            return this.props.lockedFormatter(this.props.value);
        return this.props.value;
    }

    render() {
        if (this.props.isLocked)
            return <span className={this.props.className}>{this.formatLockedValue()}</span>
        return <Input
            className={this.props.className}
            name={this.props.name}
            autoFocus={this.props.autoFocusOnMount}
            onBlur={event => {
                if(this.props.onBlurHandler)
                    this.props.onBlurHandler(event);
                }
            }
            value={this.props.value}
            {...this.props.inputProperties}
        />;

    }
}

LockableLineItemField.propTypes = {
    isLocked: PropTypes.bool,
    inputProperties: PropTypes.object.isRequired,
    value: PropTypes.string,
    onBlurHandler: PropTypes.func,
    lockedFormatter: PropTypes.func,
    className: PropTypes.string,
    autoFocusOnMount: PropTypes.bool
};


export default LockableLineItemField;

import React, { useState } from 'react';
import { Form, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {toast} from "react-toastify"
import QuoteToolClient from "@kjdelectronics/ps-quotetool-domain/service/QuoteToolClient";
import {getAuthCookieName} from "../../helper/auth.helper.js";
import Cookies from "universal-cookie";
const AUTH_COOKIE_NAME= getAuthCookieName();
const cookies = new Cookies();

class LoginPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: ""
        }
    }

    get quoteToolClient(){
        return new QuoteToolClient({
            baseUrl: window.location.hostname === "localhost" ? "http://localhost:3001/v1" : null,
            token: "fake"
        });
    }

    handleLogin(){
        this.quoteToolClient.login(this.state.email, this.state.password).then(async r => {
            toast.success('You are now logged in');
            console.log(r);
            await cookies.set(AUTH_COOKIE_NAME, r.jwt, {path: '/', secure: window.location.hostname !== 'localhost'});
            window.location.replace("/");
        }).catch(err => {
            toast.error('Login invalid. Check username and password');
            this.setState({password: ""});
        });
    }

    render(){
        return (
            <div className="auth-container">
                <div className="auth-form-container">
                    <h1 className="auth-header">Quote Tool Login</h1>
                    <Form>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Input
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                required
                                className="bootstrap-input"
                                value={this.state.email}
                                onChange={e=> this.setState({email: e.target.value})}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <Input
                                type="password"
                                id="password"
                                placeholder="Enter your password"
                                required
                                className="bootstrap-input"
                                value={this.state.password}
                                onChange={e=> this.setState({password: e.target.value})}

                            />
                        </div>
                        <Button color="primary" className="auth-button" onClick={() => this.handleLogin()} >
                            Login
                        </Button>
                        <div className="auth-links">
                            <Link to="/auth/forgot-password">Forgot Password?</Link>
                            <span> | </span>
                            <Link to="/auth/create-account">Create Account</Link>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default LoginPage;

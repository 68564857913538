import React from 'react';
import { Container } from 'reactstrap';
import QuoteToolNavBar from "../molecules/NavBar.js";
import UsersTable from "../molecules/tables/UsersTable.js";

const UsersPage = ({ quoteToolClient, currentUserId }) => {
    return (
        <>
            <QuoteToolNavBar />
            <Container className="text-center" style={{ marginTop: '20px' }}>
                <h1>Users</h1>
                <UsersTable quoteToolClient={quoteToolClient} currentUserId={currentUserId} />
            </Container>
        </>
    );
};

export default UsersPage;
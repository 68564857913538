import React from 'react';

const ErrorButton = () => {
    const handleClick = () => {
        throw new Error('Button click error, this is an intended error');
    };

    return (
        <button onClick={handleClick}>
            Click to trigger error
        </button>
    );
};

export default ErrorButton;
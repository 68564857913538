import React, {useState} from "react";
import PropTypes from "prop-types";
import AppRoutes from "./AppRoutes.js";
import AuthRoutes from "./AuthRoutes.js";
import UserPendingPage from "../pages/UserPendingPage.js";
import LoadingSpinner from "../atoms/loading/LoadingSpinner.js";
import {Progress} from "reactstrap";

const APP_ACCESS_ROLE_NAME = "sales";

const redirectToLogin = () => {
    if (!window.location.pathname.startsWith("/auth")) {
        window.location.replace("/auth/login");
        return (
            <div style={{ textAlign: "center", verticalAlign: "middle" }}>
                <h4 style={{ marginTop: "25%" }}>Redirecting to login...</h4>
            </div>
        );
    }
};

const RouterManager = ({ isLoading, user, showErrorButton, quoteToolClient, isAuthRoute,
                          isLocalHostApiRoute, error, loadingProgress}) => {
    const [message, setMessage] = useState("");
    const [showLoadingScreen, setShowLoadingScreen] = useState(false);
    console.log("RouterPicker", isLoading, isLocalHostApiRoute, isAuthRoute);

    setTimeout(() => setShowLoadingScreen(true), 200);
    setTimeout(() => setMessage("A few more seconds..."), 3000);

    //The order of this is very important and took some trial and error to get correct
    if (isLocalHostApiRoute) {
        window.location.href = "http://localhost:3001" + window.location.pathname;
        return <h1>Redirecting to API...</h1>;
    }

    if (isAuthRoute)
        return < AuthRoutes/>;

    if (isLoading) {
        if(showLoadingScreen)
            return <div className="text-center">
                <h1>Quote Tool Loading...</h1>
                <Progress
                    animated
                    value={loadingProgress}
                />
                <LoadingSpinner/>
                <h5 className="fst-italic">{message}</h5>
            </div>;
        else
            return ""; //Don't show anything for a superfast load
        }

    if (!user)
        return redirectToLogin();
    if (!user || !user.hasOwnProperty("roles"))
        return redirectToLogin();
    if (!user.roles.includes(APP_ACCESS_ROLE_NAME))
        return <UserPendingPage />;

    return <AppRoutes quoteToolClient={quoteToolClient} user={user} showErrorButton={showErrorButton} />

};

AppRoutes.propTypes = {
    showErrorButton: PropTypes.bool,
    user: PropTypes.object,
    error: PropTypes.object,
    loadingProgress: PropTypes.number,
    isLoading: PropTypes.bool,
    isAuthRoute: PropTypes.bool,
    isLocalHostApiRoute: PropTypes.bool,
    quoteToolClient: PropTypes.object,
}

export default RouterManager;
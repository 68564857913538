import React from "react";
import QuoteToolNavBar from "../molecules/NavBar.js";
import {Alert, Button} from "reactstrap";
import {getAuthCookieName} from "../../helper/auth.helper.js";
import Cookies from "universal-cookie";

const AUTH_COOKIE_NAME= getAuthCookieName();
const cookies = new Cookies();


class UserPendingPage extends React.Component {

    handleLogout = () => {
        cookies.remove(AUTH_COOKIE_NAME);
        window.location.replace("/auth/login");
    };

    render() {
        return (
            <>
                <div style={{textAlign: 'center', marginTop: '20px', marginLeft: '25%',  marginRight: '25%'}}>
                    <Alert>
                        <h4 className="alert-heading">
                            New User Account Pending
                        </h4>
                        <p>
                           Thank you for creating a new user account for the Quote Tool. Your account is pending approval. Please contact
                            the development team to grant your access. Once access has been granted please click the button below to login.
                        </p>
                        <hr />
                        <Button color="primary" onClick={() => this.handleLogout()}>Login</Button>
                    </Alert>
                </div>
            </>
        );
    }
}

export default UserPendingPage;

import React, { useEffect, useState } from 'react';
import { Table, Button, Spinner, Pagination, PaginationItem, PaginationLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccountsTable = ({ quoteToolClient, searchQuery }) => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                setLoading(true);
                const result = await quoteToolClient.getAccounts(searchQuery);
                setAccounts(result);
                setCurrentPage(1); // Reset to the first page when searchQuery changes
            } catch (error) {
                toast.error('Error fetching accounts');
            } finally {
                setLoading(false);
            }
        };

        const debounceFetch = setTimeout(() => {
            fetchAccounts();
        }, 100); // 100ms debounce for search

        return () => clearTimeout(debounceFetch); // Clear timeout on component unmount or input change
    }, [searchQuery, quoteToolClient]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (value) => {
        setRowsPerPage(Number(value));
        setCurrentPage(1); // Reset to first page when changing rows per page
    };

    const totalPages = Math.ceil(accounts.length / rowsPerPage);
    const currentAccounts = accounts.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const renderPagination = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return (
            <Pagination size="sm">
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink first onClick={() => handlePageChange(1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                </PaginationItem>

                {pageNumbers.map((number) => (
                    <PaginationItem active={number === currentPage} key={number}>
                        <PaginationLink onClick={() => handlePageChange(number)}>
                            {number}
                        </PaginationLink>
                    </PaginationItem>
                ))}

                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink last onClick={() => handlePageChange(totalPages)} />
                </PaginationItem>
            </Pagination>
        );
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <Table size="sm" responsive>
                <thead>
                <tr>
                    <th>Account Name</th>
                    <th>Primary Contact Name</th>
                    <th>Primary Contact Email</th>
                    <th>Notes</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {currentAccounts.map((account) => (
                    <tr key={account.id}>
                        <td>
                            <Link to={`/accounts/${account.id}`}>
                                {account.name || 'Unnamed Account'}
                            </Link>
                        </td>
                        <td>{account.contacts[0]?.name || 'N/A'}</td>
                        <td>{account.contacts[0]?.email || 'N/A'}</td>
                        <td>{account.notes || 'No notes available'}</td>
                        <td>
                            <Button size="sm" color="success" outline>
                                <Link
                                    to={`/stores/${account.defaults.storeId}/quotes?accountId=${account.id}`}
                                    style={{ color: 'inherit', textDecoration: 'none' }}
                                >
                                    <FontAwesomeIcon icon={faPlus} /> New Quote
                                </Link>
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

            {/* Pagination */}
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle caret size="sm">
                            Show {rowsPerPage} entries
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => handleRowsPerPageChange(10)}>10</DropdownItem>
                            <DropdownItem onClick={() => handleRowsPerPageChange(50)}>50</DropdownItem>
                            <DropdownItem onClick={() => handleRowsPerPageChange(100)}>100</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                {renderPagination()}
            </div>
        </>
    );
};

export default AccountsTable;
import React from 'react';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import PropTypes from 'prop-types';

const ProductPhysicalDetailsModal = ({ isOpen, toggle, product, quote }) => {
    if (!product) return null;

    const isUSStore = quote.storeId === 1000104959;

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Product Physical Details</ModalHeader>
            <ModalBody>
                <Table>
                    <tbody>
                    <tr>
                        <th>Weight</th>
                        <td>{product.weight ? product.weight : 'N/A'} {isUSStore ? 'oz' : 'grams'}</td>
                    </tr>
                    <tr>
                        <th>Width</th>
                        <td>{product.dimensions?.width ? product.dimensions.width : 'N/A'} {isUSStore ? 'inches' : 'cm'}</td>
                    </tr>
                    <tr>
                        <th>Height</th>
                        <td>{product.dimensions?.height ? product.dimensions.height : 'N/A'} {isUSStore ? 'inches' : 'cm'}</td>
                    </tr>
                    <tr>
                        <th>Depth</th>
                        <td>{product.dimensions?.depth ? product.dimensions.depth : 'N/A'} {isUSStore ? 'inches' : 'cm'}</td>
                    </tr>
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    );
};

ProductPhysicalDetailsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    product: PropTypes.object,
    isUSStore: PropTypes.bool.isRequired
};

export default ProductPhysicalDetailsModal;
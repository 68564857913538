import QuoteLineItem from "@kjdelectronics/ps-quotetool-domain/obj/quote/QuoteLineItem.js";

async function buildNewQuoteLineItemFromApiData({ sku, product, currentProductId, quoteToolClient, quote }) {
    console.log('Product data:', product);
    if (!product && currentProductId) // If there was an actual product selected before clear it
        return new QuoteLineItem({
            productId: null,
            sku: sku,
            title: "",
            quantity: 1,
        }, quote);
    else {
        console.log(product);
        const productId = product.product_variant.product_id;
        //Get the product card. This is the same method on the backend to ensure same result
        const productCard = await quoteToolClient.getSaturnDataBySaturnProductIdForStoreId({
            saturnProductId: productId,
            storeId: quote.storeId
        })
            .catch(err => {
                console.log("Failed to get product card", err); //This is not the end of the world, just won't have inventory data
                return []; //Fail with an empty list
            })

        const lineItem = new QuoteLineItem({
            productId: productId,
            sku: product.sku,
            title: product.name,
            quantity: 1,
            unitCostUsd: product?.product_warehouse?.cost_price,
            retailPrice: product.price,
            price: product.price,
            discount: 0,
            saturnData: {_raw: productCard },
            weight: product.properties?.weight || 0,
            dimensions: {
                width: product.dimensions?.width || 0,
                height: product.dimensions?.height || 0,
                depth: product.dimensions?.depth || 0
            },
        }, quote);
        console.log(lineItem);
        return lineItem;
    }
}

export default buildNewQuoteLineItemFromApiData;
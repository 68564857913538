import QuoteLineItem from "@kjdelectronics/ps-quotetool-domain/obj/quote/QuoteLineItem";

export async function buildNewQuoteLineItemFromApiData({ sku, product, quote, quoteToolClient, isFromMultipleSkuModal = false }) {
    if (!product) {
        return new QuoteLineItem({
            productId: null,
            sku: sku,
            title: "",
            quantity: 1,
        }, quote);
    } else {
        const productId = product.product_variant.product_id;
        const productCard = await quoteToolClient.getSaturnDataBySaturnProductIdForStoreId({ saturnProductId: productId, storeId: quote.storeId }).catch(() => []);

        return new QuoteLineItem({
            productId: productId,
            sku: product.sku,
            title: product.name,
            quantity: 1,
            unitCostUsd: product?.product_warehouse?.cost_price,
            retailPrice: product.price,
            price: product.price,
            discount: 0,
            saturnData: { _raw: productCard },
            weight: product.properties?.weight || 0,
            dimensions: {
                width: product.dimensions?.width || 0,
                height: product.dimensions?.height || 0,
                depth: product.dimensions?.depth || 0
            },
            isFromMultipleSkuModal: isFromMultipleSkuModal,
        }, quote);
    }
}
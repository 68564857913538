import React from 'react';
import {
    Navbar,
    Row,
    Col,
    NavbarText, NavItem, NavLink, Nav, DropdownItem, DropdownMenu, DropdownToggle, Dropdown
} from 'reactstrap';
import AppContext from "../../context/AppContext";
import PropTypes from "prop-types";
import { faFileInvoice, faSearch } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import withRouter from "../../helper/withRouter";
import {Link} from "react-router-dom";
import Cookies from "universal-cookie";
import {getAuthCookieName} from "../../helper/auth.helper.js";
const AUTH_COOKIE_NAME= getAuthCookieName();

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
        };
        this.cookies = new Cookies();

        // Binding the function allows the method to have access to 'this' without using an arrow function
        this.handleLogoClick = this.handleLogoClick.bind(this);
    }

    handleLogoClick() {
        this.props.router.navigate('/stores');
    }

    _renderLogo(store) {
        console.log('Rendering logo for store:', store);
        if (this.props.logoUrl)
            return <img src={this.props.logoUrl} width="50" alt="Logo" onClick={this.handleLogoClick}
                        style={{ cursor: 'pointer' }} />;
        if (store)
            return <img style={{ backgroundColor: "white" }} src={`https://web.warehouseparadise.com/${store.logo}`}
                        width="125" alt="Logo" onClick={this.handleLogoClick} style={{ cursor: 'pointer' }} />;

        return <FontAwesomeIcon icon={faFileInvoice} color="white" className="fa-4x" onClick={this.handleLogoClick}
                                style={{ cursor: 'pointer' }} />;
    }

    _getStore(storeId, stores) {
        console.log('Getting store for storeId:', storeId);
        if (!storeId) {
            console.warn('Store ID is undefined');
            return null;
        }
        const store = stores.find(store => store.id === parseInt(storeId));
        console.log('Mapped store:', store);
        if (!store) {
            console.error('Store not found for ID:', storeId);
            throw new Error(`Store not found for ID: ${storeId}`);
        }
        return store;
    }

    static _getDefaultHeading(store){
        if(!store)
            return "Quote Tool";
        return `${store.short_name} - Quote Tool`;
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    };

    handleLogout = () => {
        this.cookies.remove(AUTH_COOKIE_NAME);
        window.location.replace("/auth/login");
    };

    render() {
        return (
            <AppContext.Consumer>
                {({ user, saturnStores }) => {
                    if(!saturnStores)
                        return "";

                    console.log("stores", saturnStores);

                    const storeId = this.props?.router?.params?.storeId;
                    const store = this._getStore(storeId, saturnStores);
                    console.log('Mapped store:', store);

                    return (
                        <Navbar color="dark" dark expand="lg" container="fluid">
                            <Row>
                                <Col xl={5}>
                                    {this._renderLogo(store)}
                                </Col>
                                <Col xl={7}>
                                    <div className="quote-tool-title">
                                        <Row>
                                            <Col>
                                                <strong className="store-name">{this.props.heading ? this.props.heading : NavBar._getDefaultHeading(store)}</strong>
                                            </Col>
                                        </Row>
                                        <span className="font-italic">{this.props.subHeading ? this.props.subHeading : ""}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Nav className="me-auto" navbar>
                                <div className="navbar-container">
                                    <ul className="navbar-links">
                                        <li>
                                            <Link className="navbar-link" to="/quotations">Quotations</Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-link" to="/accounts">Accounts</Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-link" to="/stores">Stores</Link>
                                        </li>
                                        {user.roles.includes("admin") && <li>
                                            <Link className="navbar-link" to="/admin/users">Users</Link>
                                        </li>}
                                    </ul>
                                    <div className="navbar-search">
                                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="form-control search-input"
                                            style={{ width: "200px", display: "inline-block" }}
                                        />
                                    </div>
                                </div>
                            </Nav>


                            <NavbarText className="me-5">
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                    <DropdownToggle caret>
                                        {user.firstName ? `Hello, ${user.firstName}` : "Welcome"}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} to="/users/change-password">
                                            Change Password
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={this.handleLogout}>Logout</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </NavbarText>
                        </Navbar>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

NavBar.propTypes = {
    logoUrl: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
};

export default withRouter(NavBar);
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
    Button,
    InputGroup,
    InputGroupText,
    Badge,
    Tooltip, Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faX,
    faBoxOpen,
    faInfoCircle,
    faPencilAlt,
    faSearch,
} from '@fortawesome/free-solid-svg-icons';
import LockableLineItemField from '../atoms/inputs/LockableLineItemField.js';
import NumericInput from '../atoms/inputs/NumericInput.js';
import TitleSearchModal from './modals/TitleSearchModal.js';
import ProductPhysicalDetailsModal from './modals/ProductPhysicalDetailsModal.js';
import buildNewQuoteLineItemFromApiData from '../../domain/quote.line.item.builder.js';
import { percentFormatter, usdFormatter } from '../../helper/formatters.js';

// QuoteLineItemRow Component
const QuoteLineItemRow = ({
                              quoteToolClient,
                              lineItem,
                              index,
                              patchQuote,
                              patchQuoteAction,
                              patchLineItem,
                              setLineItem,
                              quote,
                              lineNumber,
                              storeId,
                          }) => {
    // State Hooks for Component
    const [loading, setLoading] = useState(false);
    const [isTitleSearchModalOpen, setIsTitleSearchModalOpen] = useState(false);
    const [productSearchResults, setProductSearchResults] = useState(null);
    const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] = useState(
        false
    );

    const [promoPriceTooltipOpen, setPromoPriceTooltipOpen] = useState(false);
    const [backorderTooltipOpen, setBackorderTooltipOpen] = useState(false);

    // SKU State
    const [skuLocked, setSkuLocked] = useState(
        lineItem.isFromMultipleSkuModal || !!lineItem.productId
    );
    const [skuChangeTimeout, setSkuChangeTimeout] = useState(null);

    // Title State
    const [titleLocked, setTitleLocked] = useState(
        lineItem.isFromMultipleSkuModal || !!lineItem.productId
    );

    // Editing States
    const [editingPrice, setEditingPrice] = useState(
        lineItem.price ? lineItem.price.toString() : ''
    );

    // Quantity State
    const [quantityInput, setQuantityInput] = useState(
        lineItem.quantity.toString()
    );
    const [quantityError, setQuantityError] = useState(false);

    useEffect(() => {
        setQuantityInput(lineItem.quantity.toString());
        setEditingPrice(lineItem.price ? lineItem.price.toString() : '');
    }, [lineItem.quantity, lineItem.price, lineItem.discount]);

    const handleLineItemChange = async (field, value) => {
        patchLineItem({ lineItemIndex: index, key: field, value });

        if (field === 'sku') {
            if (value.trim()) {
                clearTimeout(skuChangeTimeout)
                const timeout = setTimeout(() => handleProductSkuChanged({ sku: value }), 300);
                setSkuChangeTimeout(timeout);
            }
        }
    };

    const handleProductSkuChanged = async ({ sku }) => {
        try {
            const product = await quoteToolClient.getSaturnProductBySkuForStoreId({
                sku,
                storeId,
            });
            const newLineItemObject = await buildNewQuoteLineItemFromApiData({
                sku,
                product,
                currentProductId: lineItem.productId,
                quoteToolClient,
                quote
            });

            setLineItem({ lineItemIndex: index, newLineItemObject });
        } catch (error) {
            if (error.code === 404) {
                patchLineItem({ lineItemIndex: index, key: 'title', value: '' });
                patchLineItem({ lineItemIndex: index, key: 'productId', value: null });
                return;
            } else {
                console.error('Error searching for product:', error.message);
                toast.error(
                    'Unable to populate product details for this product. Check your connection or contact support if error persists'
                );
            }
            throw error;
        }
    };

    const handleSkuBlur = e => {
        setSkuLocked(true);
        if(lineItem.title !== "")
            setTitleLocked(true);
    }

    const handleTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const inputValue = e.target.value.trim();
            return performTitleSearch(inputValue);
        }
    };

    const performTitleSearch = async (inputValue) => {
        if (inputValue) {
            try {
                const searchResults = await quoteToolClient.searchSaturnProductsByNameForStoreId({
                    name: inputValue,
                    storeId: quote.storeId,
                });
                console.log('Search Results', searchResults);
                setIsTitleSearchModalOpen( searchResults.length > 0);
                setProductSearchResults(searchResults);
            } catch (error) {
                toast.error(
                    <div>
                        <div>
                            <b>Product Search Error</b>
                        </div>
                        {error.userMessage}
                    </div>
                );
                console.error('Error searching for product:', error);
            }
        }
    };

    const toggleModal = (modalSetter) => {
        modalSetter((prev) => !prev);
    };

    const handleQuantityChange = (value) => {
        const quantity = parseInt(value, 10);
        const isOverQuantity =
            quantity > (lineItem.saturnData?.productWarehouse?.available || 0);
        patchLineItem({ lineItemIndex: index, key: 'quantity', value: quantity });
        setQuantityError(isOverQuantity);
        setQuantityInput(quantity.toString());
    };

    const handleDiscountButtonClick = (value) => {
        patchLineItem({ lineItemIndex: index, key: 'discount', value: value });
    };

    const doesLineItemMatchDiscount = (discount) => {
        const actualDiscountPercent = (1 - lineItem.price / lineItem.retailPrice) * 100; // Convert to percentage
        const tolerance = 1; // 1% tolerance

        // Check if the actual discount is within the tolerance range
        return (
            actualDiscountPercent >= discount - tolerance &&
            actualDiscountPercent <= discount + tolerance
        );
    };
    const handleBackorderChange = () => {
        patchLineItem({
            lineItemIndex: index,
            key: 'isBackordered',
            value: !lineItem.isBackordered,
        });
    };

    const handleProductSelected = async (sku) => {
        setLoading(true);
        try {
            await handleProductSkuChanged({ sku });
        } catch (err) {
            console.error('Error while populating product from search. Row will not be locked', err);
        } finally {
            setLoading(false);
        }
    };

    const _renderAvailable = (available) => {
        if (isNaN(available))
            return <span className="small-italic">Not Avail</span>;
        const inventoryClass = available - lineItem.quantity <= 0 ? 'profit-negative' : '';
        //const oldString = `${available} (${available - lineItem.quantity})`;
        return <span className={inventoryClass}>{available}</span>;
    };

    const isDuplicate = quote.lineItems.filter(item => item.sku === lineItem.sku).length > 1;

    return (
        <tr key={index}>
            <td>{lineNumber}</td>
            <td>
                {/*SKU*/}
                <InputGroup>
                    <LockableLineItemField
                        value={lineItem.sku}
                        isLocked={skuLocked}
                        onBlurHandler={handleSkuBlur}
                        inputProperties={{
                            onChange: (e) => handleLineItemChange('sku', e.target.value),
                            style: { color: isDuplicate ? 'red' : 'black' },
                        }}
                    />
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={() => setSkuLocked((prev) => !prev)}
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                    />
                    {lineItem.sku && isDuplicate && (
                        <Badge color="danger" pill>
                            Duplicate SKU
                        </Badge>
                    )}
                </InputGroup>
            </td>
            <td>
                {/*TITLE*/}
                <LockableLineItemField
                    value={lineItem.title}
                    isLocked={titleLocked}
                    onBlurHandler={() => setTitleLocked(true)}
                    inputProperties={{
                        onChange: (e) => handleLineItemChange('title', e.target.value),
                        onKeyDown: handleTitleKeyDown,
                    }}
                />
                {titleLocked ? (
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={() => setTitleLocked(false)}
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faSearch}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            toggleModal(setIsTitleSearchModalOpen);
                        }}
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                    />
                )}
                <TitleSearchModal
                    isOpen={isTitleSearchModalOpen}
                    productsData={productSearchResults}
                    onClose={() => setIsTitleSearchModalOpen(false)}
                    onProductSelected={handleProductSelected}
                />
            </td>
            <td>
                {/*QUANTITY*/}
                <NumericInput
                    value={quantityInput}
                    onChange={handleQuantityChange}
                    decimalPlaces={0}
                    className="text-center"
                    style={{
                        borderColor: quantityError ? 'red' : 'initial',
                        minWidth: '7ch',
                        maxWidth: '50px',
                    }}
                />
            </td>
            <td className="inventory-level">
                {
                    /*INVENTORY/BO*/
                    _renderAvailable(lineItem.saturnData?.productWarehouse?.available)
                }
                {lineItem.sku && (
                    <>
                        <Input
                            type="checkbox"
                            checked={lineItem.isBackordered}
                            onChange={handleBackorderChange}
                            id={`backorder-checkbox-${index}`}
                            style={{outline: '1px solid black', marginLeft: '3px' }}
                        />
                        <Tooltip
                            placement="top"
                            isOpen={backorderTooltipOpen}
                            target={`backorder-checkbox-${index}`}
                            toggle={() => setBackorderTooltipOpen(!backorderTooltipOpen)}
                        >
                            Backordered Status
                        </Tooltip>
                    </>
                )}
            </td>
            <td>
                {/*UNIT COST*/}
                <LockableLineItemField
                    value={lineItem.unitCost}
                    lockedFormatter={usdFormatter}
                    isLocked={!!lineItem.productId}
                    inputProperties={{
                        type: 'number',
                        onChange: (e) => handleLineItemChange('unitCostUsd', parseFloat(e.target.value)),
                    }}
                />
            </td>
            <td>
                {/* RETAIL PRICE */}
                {lineItem.salePrice ? (
                    <>
                        <LockableLineItemField
                            value={lineItem.salePrice}
                            lockedFormatter={usdFormatter}
                            isLocked={!!lineItem.productId}
                            inputProperties={{
                                type: "number",
                                onChange: (e) => handleLineItemChange('salePrice', parseFloat(e.target.value))
                            }}
                            className="sale-price"
                        />
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            id={`tooltip-${index}`}
                            style={{ marginLeft: '5px', color: 'blue', cursor: 'pointer' }}
                        />
                        <Tooltip
                            placement="top"
                            isOpen={promoPriceTooltipOpen}
                            target={`tooltip-${index}`}
                            toggle={() => setPromoPriceTooltipOpen(!promoPriceTooltipOpen)}
                        >
                            Promotional Price! Normally: ${lineItem.retailPrice}
                        </Tooltip>
                    </>
                ) : (
                    <LockableLineItemField
                        value={lineItem.retailPrice}
                        lockedFormatter={usdFormatter}
                        isLocked={!!lineItem.productId}
                        inputProperties={{
                            type: 'number',
                            onChange: (e) => handleLineItemChange('retailPrice', parseFloat(e.target.value)),
                        }}
                    />
                )}
            </td>
            <td>
                {/* Discount Buttons */}
                <div className="discount-button-grid">
                    {[0, 5, 10, 20].map((discountValue) => (
                        <Button
                            key={discountValue}
                            color={
                                doesLineItemMatchDiscount(discountValue)
                                    ? 'primary'
                                    : 'secondary'
                            }
                            onClick={() => handleDiscountButtonClick(discountValue)}
                            style={{
                                width: '100%',
                                margin: '0px',
                                padding: '1px',
                                fontSize: '0.8em',
                            }}
                        >
                            {discountValue}%
                        </Button>
                    ))}
                </div>
            </td>
            <td>
                {/* PRICE */}
                <InputGroup>
                    <InputGroupText className="small-padding-input-group">$</InputGroupText>
                    <NumericInput
                        value={editingPrice}
                        onChange={(value) => setEditingPrice(value)}
                        onBlur={() => patchLineItem({ lineItemIndex: index, key: 'price', value: parseFloat(editingPrice) })}
                        style={{ minWidth: '9ch', maxWidth: '9ch' }}
                    />
                </InputGroup>
            </td>
            {/* PROFIT */}
            <td className={lineItem.profitTotal >= 0 ? 'profit-positive' : 'profit-negative'}>
                {usdFormatter(lineItem.profitTotal)} ({percentFormatter(lineItem.profitPercentage)})
            </td>
            {/* EXTENDED TOTAL */}
            <td>{usdFormatter(lineItem.extendedLineTotal)}</td>
            <td>
                {/* Action Icons */}
                <div className="action-icons">
                    <Button
                        color="link"
                        style={{ color: '#dc3545' }}
                        tabIndex="-1"
                        onClick={() =>
                            patchQuoteAction({ action: 'removeItem', data: { index } })
                        }
                    >
                        <FontAwesomeIcon icon={faX} className="action-icon" />
                    </Button>
                    <Button
                        color="link"
                        style={{ color: '#007bff' }}
                        tabIndex="-1"
                        onClick={() => toggleModal(setIsProductDetailsModalOpen)}
                    >
                        <FontAwesomeIcon icon={faBoxOpen} className="action-icon" />
                    </Button>
                </div>
            </td>
            {/* Product Details Modal */}
            <ProductPhysicalDetailsModal
                isOpen={isProductDetailsModalOpen}
                toggle={() => toggleModal(setIsProductDetailsModalOpen)}
                product={lineItem}
                quote={quote}
             isUSStore/>
        </tr>
    );
};

// PropTypes for QuoteLineItemRow
QuoteLineItemRow.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    lineItem: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    patchLineItem: PropTypes.func.isRequired,
    setLineItem: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    lineNumber: PropTypes.number.isRequired,
    storeId: PropTypes.number.isRequired,
};

export default QuoteLineItemRow;

import React from 'react';
import PropTypes from 'prop-types';

const ShippingOptions = ({ value, onChange, quote }) => {
    const isUSStore = quote.storeId === 1000104959;
    const totalWeight = quote.lineItems.reduce((total, item) => {
        const weightInKg = isUSStore ? item.weight / 16 : item.weight / 1000; // Convert ounces to pounds or grams to kg
        return total + (weightInKg * item.quantity);
    }, 0);

    return (
        <div className="form-column">
            <h4>Shipping Service ({totalWeight.toFixed(2)} {isUSStore ? 'lbs' : 'kg'})</h4>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    style={{maxWidth: 350}}
                    placeholder="Enter shipping option..."
                    value={value}
                    onChange={e => onChange(e.target.value)}
                />
            </div>
        </div>
    );
};

ShippingOptions.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    isUSStore: PropTypes.bool.isRequired
};

export default ShippingOptions;

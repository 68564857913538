import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {Input} from "reactstrap";

const EditableLabelWithPencilIcon = ({ defaultValue, handleNewValue, placeholderValue }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue);

    const handleSave = async () => {
        try {
            await handleNewValue(inputValue);
        } catch (error) {
            console.error('Error saving value:', error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleCancel = () => {
        setInputValue(defaultValue);
        setIsEditing(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            return handleSave();
        }
    };

    return (
        <div>
            {isEditing ? (
                <Input
                    className="form-control form-control-sm"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onBlur={handleSave}
                    autoFocus
                    onKeyDown={handleKeyPress}
                />
            ) : (
                <div className="d-flex align-items-start">
                    <em>{inputValue || placeholderValue}</em>
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="ms-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setIsEditing(true)}
                    />
                </div>
            )}
        </div>
    );
};

EditableLabelWithPencilIcon.propTypes = {
    defaultValue: PropTypes.string,
    handleNewValue: PropTypes.func.isRequired,
    placeholderValue: PropTypes.string,
};

EditableLabelWithPencilIcon.defaultProps = {
    defaultValue: '',
    placeholderValue: "..."
};

export default EditableLabelWithPencilIcon;

import React from 'react';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';

function ForgotPasswordForm() {
    return (
        <Container className="auth-container" style={{ maxWidth: "400px", paddingTop: "50px" }}>
            <h2 className="text-center">Forgot Password</h2>
            <Form>
                <h6 style={{color: "darkred", textAlign: "center"}}>NOT IMPLEMENTED YET</h6>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                    />
                </FormGroup>
                <Button disabled color="primary" block>Not Functional</Button>
            </Form>
        </Container>
    );
}

export default ForgotPasswordForm;

import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import '../../../css/Loading.css';

const Loading = () => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => (prevDots.length === 3 ? '' : prevDots + '.'));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="Loading">
            <Spinner color="primary" />
            <h2>Loading{dots}</h2>
        </div>
    );
};

export default Loading;
import React, {Component, useCallback, useEffect, useMemo, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/TempAuthPage.css';
import './css/App.css';
import './css/QuoteTable.css';
import AppProvider from "./context/AppProvider";
import Cookies from 'universal-cookie';
import QuoteToolClient from "@kjdelectronics/ps-quotetool-domain/service/QuoteToolClient";
import {getAuthCookieName} from "./helper/auth.helper.js";
import {toast, ToastContainer} from "react-toastify";
import LoadingPage from "./components/pages/LoadingPage.js";
import UserPendingPage from "./components/pages/UserPendingPage.js";
import {FALLBACK_CURRENCY_RATES} from "@kjdelectronics/ps-quotetool-domain/obj/quote/CurrencyRates.js";
import AppRoutes from "./components/routes/AppRoutes.js";
import AuthRoutes from "./components/routes/AuthRoutes.js";
import RouterManager from "./components/routes/RouterManager.js";

const cookies = new Cookies();
const AUTH_COOKIE_NAME= getAuthCookieName();

//User that do not have this role will not be granted access to the app

const App = () => {
    const [stores, setStores] = useState(null);
    const [countryData, setCountryData] = useState(null);
    const [currencyRates, setCurrencyRates] = useState(FALLBACK_CURRENCY_RATES);
    const [showErrorButton, setShowErrorButton] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(null);
    const totalTasks = 4; // Total number of API calls
    const [progress, setProgress] = useState(0);

    const IS_AUTH_ROUTE = window.location.pathname.startsWith("/auth");
    const IS_LOCALHOST_API_ROUTE = window.location.pathname.startsWith("/v1");

    /* REACT HOOKS */
    const quoteToolClient = useMemo(() => {
        const token = cookies.get(AUTH_COOKIE_NAME);
        if (!token) {
            console.error("Cannot get quote tool client without auth token");
            return null;
        }
        return new QuoteToolClient({
            baseUrl: window.location.hostname === "localhost" ? "http://localhost:3001/v1" : null,
            token,
            unauthorizedCallback: () => {
                if (window.location.pathname !== "/auth/login")
                    window.location.replace("/auth/login");
            },
        });
    }, []); // only runs once on mount

    const initApp = useCallback(async () => {
        const updateProgress = () => setProgress(prev => prev + 100 / totalTasks);

        try {
            await Promise.all([
                quoteToolClient.getSaturnStores().then(stores => {
                    setStores(stores);
                    updateProgress();
                }),
                quoteToolClient.getSaturnCountries().then(countries => {
                    setCountryData(prev => ({ ...prev, countries }));
                    updateProgress();
                }),
                quoteToolClient.getSaturnCountrySubdivisions().then(subdivisions => {
                    setCountryData(prev => ({ ...prev, countrySubdivisions: subdivisions }));
                    updateProgress();
                }),
                quoteToolClient.getSaturnCurrencyRates().then(currencyRates => {
                    setCurrencyRates(currencyRates);
                    updateProgress();
                })
            ]);

            setIsLoading(false); // Hide loading state when all promises resolve
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to load application data.");
            setIsLoading(false); // End loading on error
            setLoadingError(error); // End loading on error
        }
    }, [quoteToolClient]);


    useEffect(() => {
       if(IS_AUTH_ROUTE || IS_LOCALHOST_API_ROUTE)
           return; //Do not need to do anything

        const sessionKey = cookies.get(AUTH_COOKIE_NAME);

        if (!sessionKey && !window.location.pathname.startsWith('/auth')) {
            window.location.replace("/auth/login");
            return;
        }

        if (sessionKey) {
            initApp(); // Async data loading
        }
    }, [initApp]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Pause') {
                setShowErrorButton(prev => !prev);
            }
        };

        const handleBeforeUnload = (event) => {
            if (event.ctrlKey && event.shiftKey && event.key === 'R') {
                localStorage.removeItem('termsOptions');
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    /* RENDER */
    console.log("APP.js");

    const jsonWebToken = cookies.get(AUTH_COOKIE_NAME);
    const user = jsonWebToken ? parseJwt(jsonWebToken) : null;

    return (
        <AppProvider user={user} countryData={countryData} saturnStores={stores} currencyRates={currencyRates}>
            <RouterManager isAuthRoute={IS_AUTH_ROUTE} isLocalHostApiRoute={IS_LOCALHOST_API_ROUTE}
                           quoteToolClient={quoteToolClient} user={user} showErrorButton={showErrorButton}
                           isLoading={isLoading} error={loadingError} loadingProgress={progress}
            />
            <ToastContainer autoClose={2000} />
        </AppProvider>
    );
};

function parseJwt (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

/* Dark Mode WIP do not activate without changing the way the colors invert
const AppWithDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(darkModeMediaQuery.matches);

        const handleChange = (e) => {
            setIsDarkMode(e.matches);
        };

        darkModeMediaQuery.addEventListener('change', handleChange);
        return () => darkModeMediaQuery.removeEventListener('change', handleChange);
    }, []);

    return (
        <div className={isDarkMode ? 'dark-mode' : ''}>
            <App />
        </div>
    );
};

Use when ready: export default AppWithDarkMode;

 */

export default App;
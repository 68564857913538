import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import AddressCard from "../organism/AddressCard.js";
import AddressObject from "@kjdelectronics/ps-quotetool-domain/obj/Address.js";
import { toast } from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import Account from "@kjdelectronics/ps-quotetool-domain/obj/account/Account.js";
import {getDefaultCountryForStoreId} from "@kjdelectronics/ps-quotetool-domain/domain/helper/country.default.js";


const AddressSection = ({ account, isSelectionMode, onAddressSelected, quoteToolClient, onAccountUpdated }) => {
    const [addressEditIds, setAddressEditIds] = useState([]);
    const [newAddress, setNewAddress] = useState(false);

    const handleSetDefaultAddress = async (type, addressId) => {
        if (type !== "billing" && type !== "shipping")
            throw new Error("invalid address type " + type);
        const patch = {};
        patch[`defaults.${type}AddressId`] = addressId;

        const updatedAccount = await quoteToolClient.patchAccount({
            accountId: account.id,
            patch
        });
        onAccountUpdated(updatedAccount);
        toast.success(`Default ${type} address updated!`);
    };

    const setAddressEdit = (id, isEditing) => {
        const array = [...addressEditIds];
        const index = array.indexOf(id);
        if (index !== -1 && !isEditing) {
            array.splice(index, 1);
        } else if (index === -1 && isEditing) {
            array.push(id);
        }
        setAddressEditIds(array);
    };

    const handleAddAddress = () => {
        const country = getDefaultCountryForStoreId(account.defaults.storeId);
        const newAddr = new AddressObject({
            country
        });
        const newAccount = new Account({
            ...account,
            addresses: [...account.addresses, newAddr],
        });
        onAccountUpdated(newAccount);
        setNewAddress(true);
    };

    return (
        <>
            <Row style={{ alignItems: 'center' }}>
                <Col>
                    <h3>Addresses</h3>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                    <Button
                        color="primary"
                        outline
                        onClick={handleAddAddress}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} /> Add Address
                    </Button>
                </Col>
            </Row>
            <Row>
                {account.addresses.sort((a, b) => {
                    if (a.id === account.defaults.billingAddressId || a.id === account.defaults.shippingAddressId) return -1;
                    if (b.id === account.defaults.billingAddressId || b.id === account.defaults.shippingAddressId) return 1;
                    return 0;
                }).map((address, index) => (
                    <Col sm="6" md="4" key={index}>
                        <AddressCard
                            address={address}
                            onAccountUpdated={onAccountUpdated}
                            isDefaultBilling={address.id === account.defaults.billingAddressId}
                            isDefaultShipping={address.id === account.defaults.shippingAddressId}
                            account={account}
                            quoteToolClient={quoteToolClient}
                            handleSetDefaultAddress={handleSetDefaultAddress}
                            addressEditInProgress={addressEditIds.length !== 0}
                            setAddressEditInProgress={isEditing => setAddressEdit(address.id, isEditing)}
                            isNewAddress={newAddress && index === account.addresses.length - 1}
                            onClearNewAddress={() => {
                                const updatedAddresses = [...account.addresses];
                                updatedAddresses.pop();
                                onAccountUpdated({ ...account, addresses: updatedAddresses });
                                setNewAddress(false);
                            }}
                            isSelectionMode={isSelectionMode}
                            onAddressSelected={onAddressSelected}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};

AddressSection.propTypes = {
    account: PropTypes.object.isRequired,
    isSelectionMode: PropTypes.bool,
    quoteToolClient: PropTypes.object.isRequired,
    onAccountUpdated: PropTypes.func.isRequired,
    onAddressSelected: PropTypes.func
};

export default AddressSection;

import React from 'react';
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import AppContext from "../../context/AppContext";
import AddressObject from "@kjdelectronics/ps-quotetool-domain/obj/Address.js";
import { getDefaultCountryForStoreId } from "@kjdelectronics/ps-quotetool-domain/domain/helper/country.default.js";
import CountryDropdown from "./inputs/CountryDropdown.js";

class Address extends React.Component {
    constructor(props, context) {
        super(props);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleSubdivisionChange = this.handleSubdivisionChange.bind(this);
        this.postalCodeRef = React.createRef();
        this.phoneRef = React.createRef();
    }

    handleInputChange({ fieldName, value }) {
        const newAddress = new AddressObject(this.props.address);
        newAddress[fieldName] = value;
        this.props.onAddressUpdated(newAddress);
    }

    handleCountryChange({ country }) {
        this.handleInputChange({ fieldName: 'country', value: country });
    }

    handleSubdivisionChange({ stateProvince }) {
        this.handleInputChange({ fieldName: 'stateProvince', value: stateProvince });
    }

    _getTabIndex() {
        if (this.props.blockTab)
            return { tabIndex: -1 };
    }

    render() {
        if (!this.props.address)
            throw new Error("Must provide address as a param to Address");

        return (
            <div className="address-container">
                <div className="form-column">
                    <h4>{this.props.header || "Address"}</h4>
                    <Row className="form-line input-group-sm">
                        <Col>
                            <div className="form-line input-group-sm">
                                <input type="text" className="form-control input-group-sm" placeholder="First Name"
                                       {...this._getTabIndex()}
                                       value={this.props.address.firstName}
                                       onChange={e => this.handleInputChange({
                                           fieldName: "firstName",
                                           value: e.target.value
                                       })}
                                /></div>
                        </Col>
                        <Col>
                            <div className="form-line input-group-sm">
                                <input type="text" className="form-control input-group-sm" placeholder="Last Name"
                                       {...this._getTabIndex()}
                                       value={this.props.address.lastName}
                                       onChange={e => this.handleInputChange({
                                           fieldName: "lastName",
                                           value: e.target.value
                                       })}
                                /></div>
                        </Col>
                    </Row>
                    <div className="form-line input-group-sm">
                        <input type="text" className="form-control input-sm" placeholder="Company/School"
                               {...this._getTabIndex()}
                               value={this.props.address.company}
                               onChange={e => this.handleInputChange({fieldName: "company", value: e.target.value})}
                        />
                    </div>
                    <div className="form-line input-group-sm">
                        <input type="text" className="form-control" placeholder="Address 1"
                               {...this._getTabIndex()}
                               value={this.props.address.line1}
                               onChange={e => this.handleInputChange({fieldName: "line1", value: e.target.value})}
                        />
                    </div>
                    <div className="form-line input-group-sm">
                        <input type="text" className="form-control" placeholder="Address 2"
                               {...this._getTabIndex()}
                               value={this.props.address.line2}
                               onChange={e => this.handleInputChange({fieldName: "line2", value: e.target.value})}
                        />
                    </div>
                    <Row className="form-line input-group-sm">
                        <Col>
                            <div className="form-line input-group-sm">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="City"
                                    value={this.props.address.city}
                                    onChange={e => this.handleInputChange({
                                        fieldName: "city",
                                        value: e.target.value
                                    })}
                                />
                            </div>
                        </Col>
                        <Col>
                            <CountryDropdown
                                selectedCountry={this.props.address.country || ''}
                                selectedSubdivision={this.props.address.stateProvince || ''}
                                onSubdivisionChange={this.handleSubdivisionChange}
                                defaultCountry={getDefaultCountryForStoreId(this.props.storeId)}
                                isSubdivision={true}
                                nextFieldRef={this.postalCodeRef}
                            />
                        </Col>
                        <Col>
                            <div className="form-line input-group-sm">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Postal Code"
                                    value={this.props.address.postalCode}
                                    onChange={e => this.handleInputChange({
                                        fieldName: "postalCode",
                                        value: e.target.value
                                    })}
                                    ref={this.postalCodeRef}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="form-line input-group-sm">
                        <Row>
                            <Col md={6}>
                                <div className="form-line input-group-sm">
                                    <label htmlFor="phone">Phone #</label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={this.props.address.phone}
                                        onChange={e => this.handleInputChange({
                                            fieldName: "phone",
                                            value: e.target.value
                                        })}
                                        className="form-control"
                                        ref={this.phoneRef}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-line input-group-sm">
                                    <label htmlFor="country">Country</label>
                                    <CountryDropdown
                                        selectedCountry={this.props.address.country || ''}
                                        selectedSubdivision={this.props.address.stateProvince || ''}
                                        onCountryChange={this.handleCountryChange}
                                        defaultCountry={getDefaultCountryForStoreId(this.props.storeId)}
                                        isSubdivision={false}
                                        nextFieldRef={this.phoneRef}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {this.props.isNotesEditable && <div className="form-line input-group-sm">
                        <hr style={{margin: "10px"}} />
                        <input type="text" className="form-control input-sm" placeholder="Notes"
                               {...this._getTabIndex()}
                               value={this.props.address.notes}
                               onChange={e => this.handleInputChange({fieldName: "notes", value: e.target.value})}
                        />
                    </div>}
                    {this.props.addressType === "shippingAddress" &&
                        <div className="form-line input-group-sm">
                            <label>
                                <input
                                    type="checkbox"
                                    id="billingSameAsShipping"
                                    checked={this.props.isShippingAddressSameAsBillingChecked}
                                    onChange={e => this.props.handleIsShippingAddressSameAsBillingChange(e.target.checked)}
                                /> Is billing address the same as shipping address?
                            </label>
                        </div>}
                </div>
            </div>
        );
    }
}

Address.contextType = AppContext;

Address.propTypes = {
    address: PropTypes.object.isRequired,
    addressType: PropTypes.string.isRequired,
    onAddressUpdated: PropTypes.func.isRequired,
    isShippingAddressSameAsBillingChecked: PropTypes.bool,
    handleIsShippingAddressSameAsBillingChange: PropTypes.func,
    header: PropTypes.string,
    blockTab: PropTypes.bool,
    isNotesEditable: PropTypes.bool
};

export default Address;
import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal, ModalHeader, ModalBody, Button
} from 'reactstrap';
import AddressSection from '../AddressSection.js';

const AddressSelectionModal = ({ account, isOpen, onClose, onAddressSelected, quoteToolClient, onAccountUpdated }) => {
    const handleSelectAddress = (address) => {
        onAddressSelected(address);
        onClose(); // Close modal after selection
    };

    return (
        <Modal style={{"max-width": "90%"}} isOpen={isOpen} toggle={onClose} >
            <ModalHeader toggle={onClose}>Select Address</ModalHeader>
            <ModalBody>
                <AddressSection
                    account={account}
                    quoteToolClient={quoteToolClient}
                    onAddressSelected={handleSelectAddress} // Pass this down for selecting an address
                    isSelectionMode={true} // Pass flag for selection mode (if needed)
                 onAccountUpdated={onAccountUpdated}/>
                <div className="text-right mt-3">
                    <Button color="primary" outline onClick={onClose}>
                        Close
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

AddressSelectionModal.propTypes = {
    account: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAddressSelected: PropTypes.func.isRequired,
    onAccountUpdated: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
};

export default AddressSelectionModal;

import React from 'react';

const NotesField = ({ value, onChange }) => {
    return (
        <div className="form-group">
            <h4>Notes</h4>
            <textarea
                id="notes"
                className="form-control"
                rows="3"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Enter notes..."
            />
        </div>
    );
};

export default NotesField;

import React from 'react';
import '../../../css/FullScreenLoader.css';

const FullScreenLoadingSpinner = () => {
    return (
        <div className="loader-overlay">
            <div className="spinner"></div>
        </div>
    );
};

export default FullScreenLoadingSpinner;
import React from 'react';
import {Container, Form, FormGroup, Label, Input, Button, FormFeedback} from 'reactstrap';
import {testPasswordStrength} from '@kjdelectronics/ps-quotetool-domain/domain/PasswordStrengthTest.js'
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import {getAuthCookieName} from "../../helper/auth.helper.js";
import QuoteToolClient from "@kjdelectronics/ps-quotetool-domain/service/QuoteToolClient";
const AUTH_COOKIE_NAME= getAuthCookieName();
const cookies = new Cookies();

class CreateAccountPage extends React.Component {
    constructor(props, context) {
        super(props);
        this.state = {
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            confirmPasswordValidityEnabled: false,
            submitPending: false
        }
    }

    get isConfirmPasswordValid(){
        if(!this.state.confirmPasswordValidityEnabled)
            return null;
        return this.state.confirmPassword === this.state.password;
    }

    get quoteToolClient(){
        return new QuoteToolClient({
            baseUrl: window.location.hostname === "localhost" ? "http://localhost:3001/v1" : null,
            token: "fake"
        });
    }

    async handleSubmit(){
        await this.setState({submitPending: true, confirmPasswordValidityEnabled: true});
        if(this.isConfirmPasswordValid.strong === false) {
            toast.error('Password not valid');
            console.log("Invalid password")
            return;
        }
        cookies.remove(AUTH_COOKIE_NAME);
        this.quoteToolClient.createNewUser({
            email: this.state.email,
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
        })
            .then(result => {
                console.log(result);
                toast.success('New user created!');
                window.location.replace("/");
            })
            .catch(err => {
                console.log(err);
                toast.error('Failed to create new user');
            })
            .finally(r => {
                this.setState({submitPending: false});
            })
    }

    render(){
        const passwordStrengthCheckResult =  testPasswordStrength(this.state.password);

        return (
            <Container className="auth-container" style={{ maxWidth: "400px", paddingTop: "50px" }}>
                <h2 className="text-center" style={{marginRight: "10px"}}>Create User</h2>
                <Form>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your email"

                            onChange={e => this.setState({email: e.target.value})}
                            value={this.state.email}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="First Name"
                            className="auth-input"
                            onChange={e => this.setState({firstName: e.target.value})}
                            value={this.state.firstName}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">Last Name</Label>
                        <Input
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            className="auth-input"
                            onChange={e => this.setState({lastName: e.target.value})}
                            value={this.state.lastName}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Enter your password"
                            onChange={e => this.setState({password: e.target.value})}
                            value={this.state.password}
                            valid={passwordStrengthCheckResult.strong === true}
                            invalid ={this.state.password.length > 0 && passwordStrengthCheckResult.strong === false}
                        />
                        <FormFeedback invalid>
                            Invalid Password
                            <ul>
                                {passwordStrengthCheckResult.errors.map(i => <li>{i}</li>)}
                            </ul>
                        </FormFeedback>
                        <FormFeedback valid>
                            Strong password!
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="confirmPassword">Confirm Password</Label>
                        <Input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirm your password"
                            onChange={e => this.setState({confirmPassword: e.target.value})}
                            value={this.state.confirmPassword}
                            valid={this.isConfirmPasswordValid === true}
                            invalid={this.isConfirmPasswordValid === false}
                            onBlur={() => this.setState({ confirmPasswordValidityEnabled: true })}
                        />
                    </FormGroup>
                    <FormFeedback valid>
                        Passwords match!
                    </FormFeedback>
                    <FormFeedback invalid>
                        Passwords do not match
                    </FormFeedback>
                    <Button color="primary" onClick={() => this.handleSubmit()} block>Create Account</Button>
                </Form>
            </Container>
        )
    }
}

export default CreateAccountPage;

import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import PropTypes from "prop-types";
import {QuoteOrganism} from "../../organism/QuoteOrganism.js";

class TitleSearchModal extends Component {
    onClose = () => {
        console.log("Closing modal...");
        // Close the modal
        this.props.onClose();
    }
    render() {
        const { isOpen, onClose, productsData  } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={onClose} centered style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ModalHeader toggle={onClose}>Title Search</ModalHeader>
                <ModalBody>
                    {productsData && productsData.length > 0 ? (
                        <table style={{width: '100%'}}>
                            <colgroup>
                                <col style={{width: 'auto'}}/>
                                <col style={{width: 'auto'}}/>
                            </colgroup>
                            <thead>
                            <tr style={{borderBottom: '3px solid #ccc'}}>
                                <th>SKU</th>
                                <th>Title</th>
                            </tr>
                            </thead>
                            <tbody>
                            {productsData.map((item, index) => (
                                <tr key={index} style={{borderBottom: '1px solid #ccc'}}>
                                    <td style={{width: "30%"}}>
                                        <Button color="link" className="py-0"
                                           onClick={() => {
                                               this.props.onProductSelected(item.sku, item.name);
                                               this.onClose();
                                           }}>{item.sku}</Button>
                                    </td>
                                    <td>{item.name}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No results found</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button className="close-button-styles" onClick={onClose}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

TitleSearchModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    productsData: PropTypes.array.isRequired,
    onProductSelected: PropTypes.func.isRequired
};

export default TitleSearchModal;

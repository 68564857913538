import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Modal, ModalHeader, ModalBody, Button, Col, Spinner
} from 'reactstrap';
import AddressSection from './AddressSection';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import Address from "../atoms/Address.js";
import {getDefaultCountryForStoreId} from "@kjdelectronics/ps-quotetool-domain/domain/helper/country.default.js";
import AddressObj from "@kjdelectronics/ps-quotetool-domain/obj/Address.js";

const QuoteDetailAddress = ({ quote, header, addressType, handleSelectExitingButtonClick, quoteToolClient, handleAddressUpdate, patchQuote, patchQuoteAction }) => {
    const [loading, setLoading] = useState(false); // Add loading state

    if (addressType !== "shippingAddress" && addressType !== "billingAddress")
        throw new Error("Must specify valid address type. Got " + addressType);

    const clearAddress = () => {
        patchQuote({
            keyArray: ["addresses", addressType],
            newValue: new AddressObj({
                country: getDefaultCountryForStoreId(quote.storeId)
            })
        });
    }

    const handleIsShippingAddressSameAsBillingChange = (value) => {
        patchQuote({
            keyArray: ["addresses", "isBillingSameAsShippingAddress"],
            newValue: value,
        });

        if (value) {
            patchQuote({
                keyArray: ["addresses", "billingAddress"],
                newValue: new AddressObj(quote.addresses.shippingAddress),
            });
        }
    };


    const handleUpdate = async () => {
        setLoading(true); // Set loading to true
        try {
            const account = await quoteToolClient.patchAccountAddress({
                accountId: quote.account.id,
                addressId: quote.addresses[addressType].id,
                patch: quote.addresses[addressType]
            });
            //Update the account on the quote
            patchQuote({
                keyArray: ["account"],
                newValue: account
            });
        } finally {
            setLoading(false); // Set loading to false after completion
        }
    };

    const handleSaveNew = async () => {
        setLoading(true); // Set loading to true
        try {
            const account = await quoteToolClient.createAccountAddress({
                accountId: quote.account.id,
                address: quote.addresses[addressType]
            });
            //Update the account on the quote
            patchQuote({
                keyArray: ["account"],
                newValue: account
            });
        } finally {
            setLoading(false); // Set loading to false after completion
        }
    };

    const renderButtons = () => {
        if(addressType === "billingAddress" && quote.addresses.isBillingSameAsShippingAddress)
            return <div className="fst-italic">Billing same as shipping</div>
        if(quote.account?.id == null){ //If no account selected just show the clear button
            return(<Button
                color="primary"
                outline={true}
                className="mx-2"
                size="sm"
                onClick={clearAddress} // Call handleUpdate
            >
                <FontAwesomeIcon icon={faTrash} /> Clear
            </Button>)
                }
        return (<>
            <Button
                color="primary"
                size="sm"
                className="mx-2"
                outline
                onClick={() => handleSelectExitingButtonClick(addressType)}
                disabled={quote.account?.addresses.length === 0} // Disable if no saved addresses
            >
                Select Existing ({quote.account?.addresses.length})
            </Button>
            <Button
                color="primary"
                outline={true}
                className="mx-2"
                size="sm"
                onClick={clearAddress} // Call handleUpdate
            >
                <FontAwesomeIcon icon={faTrash} /> Clear
            </Button>
            {quote.account?.getAddressById(quote.addresses[addressType]?.id) ? (
                   <Button
                       color="primary"
                       size="sm"
                       onClick={handleUpdate} // Call handleUpdate
                   >
                       <FontAwesomeIcon icon={faSave} /> Update
                   </Button>
            ) : (
                <Button
                    color="success"
                    size="sm"
                    onClick={handleSaveNew} // Call handleSaveNew
                >
                    <FontAwesomeIcon icon={faPlus} /> Save as New
                </Button>
            )}
            </>)
    }

    return (
        <Col xs={12} md={5} style={{ position: 'relative' }}>
            {/* Loading Spinner Overlay */}
            {loading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2,
                }}>
                    <Spinner color="primary" />
                </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h4 className="justify-content-md-start">{header}</h4>
                <div className="justify-content-md-end">
                    {renderButtons()}
                </div>
            </div>

            <Address
                address={quote.addresses[addressType]}
                addressType={addressType}
                storeId={quote.storeId}
                header={" "}
                isShippingAddressSameAsBillingChecked={quote.addresses.isBillingSameAsShippingAddress}
                handleIsShippingAddressSameAsBillingChange={handleIsShippingAddressSameAsBillingChange}
                onAddressUpdated={newAddress => handleAddressUpdate({
                    addressType,
                    newAddress
                })}
            />
        </Col>
    );
};

QuoteDetailAddress.propTypes = {
    quote: PropTypes.object.isRequired,
    header: PropTypes.string.isRequired,
    addressType: PropTypes.string.isRequired,
    handleSelectExitingButtonClick: PropTypes.func.isRequired,
    handleAddressUpdate: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
};

export default QuoteDetailAddress;
import React, { useState } from 'react';
import { Container, Input } from 'reactstrap';
import withRouter from "../../helper/withRouter.js";
import QuoteToolNavBar from "../molecules/NavBar.js";
import AccountsTable from "../molecules/tables/AccountsTable.js";

const AccountsPage = ({ quoteToolClient }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <QuoteToolNavBar />
            <Container className="text-center" style={{ marginTop: '20px' }}>
                <h1>Accounts</h1>
                <Input
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Account Search"
                    style={{ fontSize: '1.2rem', marginBottom: '10px', width: '100%', textAlign: 'left' }}
                />
                <AccountsTable quoteToolClient={quoteToolClient} searchQuery={searchQuery} />
            </Container>
        </>
    );
};

export default withRouter(AccountsPage);

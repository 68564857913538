import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MultipleSkuModal = ({ isOpen, toggle, handleSkuAdd, quoteToolClient, storeId, onAddSkus }) => {
    const [input, setInput] = useState('');
    const [result, setResult] = useState({ validSkus: [], errors: [], totalAccepted: 0 });

    const handleInputChange = (e) => {
        setInput(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const handleSkuInput = async () => {
        const skus = input.split(/[\n,;]+/).map(sku => sku.trim()).filter(sku => sku);
        console.log('Attempting to fetch SKUs:', skus);
        const validSkus = [];
        const errors = [];

        for (let sku of skus) {
            try {
                const response = await quoteToolClient.getSaturnProductBySkuForStoreId({ sku, storeId });
                if (response) validSkus.push(sku);
                else errors.push(`SKU not found: ${sku}`);
            } catch (error) {
                console.error('Error fetching SKU:', error);
                errors.push(`Error for SKU: ${sku}`);
            }
        }

        setResult({ validSkus, errors, totalAccepted: validSkus.length });
    };

    const handleAddSkus = () => {
        const skus = input.split(/[\n,;]+/).map(sku => sku.trim()).filter(sku => sku);

        if (result.errors.length > 0) {
            toast.error("Cannot add SKUs with errors. Please fix the errors before proceeding.");
            return;
        }

        handleSkuAdd(skus);
        handleClose();
    };

    const handleClose = () => {
        setInput('');
        setResult({ validSkus: [], errors: [], totalAccepted: 0 });
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>Add Multiple SKUs</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="skuInput">Enter SKUs (separated by newline, comma, or semicolon):</Label>
                    <Input
                        type="textarea"
                        id="skuInput"
                        value={input}
                        onChange={handleInputChange}
                        style={{ resize: 'none' }}
                    />
                </FormGroup>
                <Button color="primary" onClick={handleSkuInput}>
                    Validate SKUs
                </Button>
                <div>
                    <p>Total Accepted SKUs: {result.totalAccepted}</p>
                    {result.errors.length > 0 && (
                        <ul style={{ color: 'red' }}>
                            {result.errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={handleAddSkus}
                    disabled={result.errors.length > 0}
                >
                    Add SKUs
                </Button>

                <Button color="secondary" onClick={handleClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default MultipleSkuModal;

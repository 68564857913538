import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';

const UsersTable = ({ quoteToolClient, currentUserId }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingUser, setEditingUser] = useState(null); // track editing state by user ID
    const [updatingUser, setUpdatingUser] = useState(null); // track loading state when patching

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const usersList = await quoteToolClient.getUsers();
                setUsers(usersList);
            } catch (error) {
                toast.error('Error fetching users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [quoteToolClient]);

    const toggleRole = async (userId, role) => {
        try {
            const user = users.find(u => u.id === userId);
            const newRoles = user.roles.includes(role)
                ? user.roles.filter(r => r !== role)
                : [...user.roles, role];
            const updatedUser = await quoteToolClient.patchUser({
                userId,
                patch: { roles: newRoles }
            });
            setUsers(users.map(u => (u.id === userId ? updatedUser : u)));
        } catch (error) {
            toast.error('Error updating user roles');
        }
    };

    const toggleDisabled = async (userId, isDisabled) => {
        try {
            const updatedUser = await quoteToolClient.patchUser({
                userId,
                patch: { isDisabled: !isDisabled }
            });
            setUsers(users.map(u => (u.id === userId ? updatedUser : u)));
        } catch (error) {
            toast.error('Error updating user status');
        }
    };

    const startEditing = (userId, field) => {
        setEditingUser({ userId, field });
    };

    const handleBlur = async (userId, field, value) => {
        try {
            setUpdatingUser(userId);
            const updatedUser = await quoteToolClient.patchUser({
                userId,
                patch: { [field]: value }
            });
            setUsers(users.map(u => (u.id === userId ? updatedUser : u)));
        } catch (error) {
            toast.error('Error updating user details');
        } finally {
            setEditingUser(null);
            setUpdatingUser(null);
        }
    };

    const renderButtons = (user, currentUserId) => {
        if(user.id === currentUserId)
            return "";
        return <><Button
            size="sm"
            color={user.roles.includes('admin') ? 'primary' : 'secondary'}
            onClick={() => toggleRole(user.id, 'admin')}
            disabled={user.id === currentUserId}
        >
            {user.roles.includes('admin') ? 'Remove Admin' : 'Add Admin'}
        </Button>{' '}
        <Button
            size="sm"
            color={user.roles.includes('sales') ? 'primary' : 'secondary'}
            onClick={() => toggleRole(user.id, 'sales')}
            disabled={user.id === currentUserId}
        >
            {user.roles.includes('sales') ? 'Remove Sales' : 'Add Sales'}
        </Button>{' '}
        <Button
            size="sm"
            outline={true}
            color={user.isDisabled ? 'success' : 'danger'}
            onClick={() => toggleDisabled(user.id, user.isDisabled)}
            disabled={user.id === currentUserId}
        >
            {user.isDisabled ? 'Enable User' : 'Disable User'}
        </Button></>
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <Table size="sm" responsive>
            <thead>
            <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Roles</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {users.map((user) => (
                <tr key={user.id}>
                    <td>
                        {editingUser?.userId === user.id && editingUser.field === 'email' ? (
                            <Input
                                type="email"
                                defaultValue={user.email}
                                onBlur={(e) => handleBlur(user.id, 'email', e.target.value)}
                                disabled={updatingUser === user.id}
                                bsSize="sm"
                            />
                        ) : (
                            <span>
                                    {user.email}
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    onClick={() => startEditing(user.id, 'email')}
                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                />
                                </span>
                        )}
                    </td>
                    <td>
                        {editingUser?.userId === user.id && editingUser.field === 'firstName' ? (
                            <Input
                                type="text"
                                defaultValue={user.firstName}
                                onBlur={(e) => handleBlur(user.id, 'firstName', e.target.value)}
                                disabled={updatingUser === user.id}
                                bsSize="sm"
                            />
                        ) : (
                            <span>
                                    {user.firstName}
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    onClick={() => startEditing(user.id, 'firstName')}
                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                />
                                </span>
                        )}
                    </td>
                    <td>
                        {editingUser?.userId === user.id && editingUser.field === 'lastName' ? (
                            <Input
                                type="text"
                                defaultValue={user.lastName}
                                onBlur={(e) => handleBlur(user.id, 'lastName', e.target.value)}
                                disabled={updatingUser === user.id}
                                bsSize="sm"
                            />
                        ) : (
                            <span>
                                    {user.lastName}
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    onClick={() => startEditing(user.id, 'lastName')}
                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                />
                                </span>
                        )}
                    </td>
                    <td>
                        <code>{user.roles.join(', ')}</code>
                    </td>
                    <td>
                        {renderButtons(user, currentUserId)}
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default UsersTable;
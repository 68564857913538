import React, {Component} from "react";
import PropTypes from "prop-types";

class TaxableSwitch extends Component {
    render() {
        return <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">{this.props.text ? this.props.text : "Taxable?" }</label>
            <div className="col-sm-8">
                <div className="btn-group" role="group" aria-label="Taxable">
                    <button
                        type="button"
                        id="taxable-btn"
                        className={this.props.isTaxable ? "taxable-button active" : "taxable-button"}
                        onClick={e => this.props.onChange(true)}
                    >
                        Taxable
                    </button>
                    <button
                        type="button"
                        id="not-taxable-btn"
                        className={this.props.isTaxable === false ? "not-taxable-button active" : "not-taxable-button"}
                        onClick={e => this.props.onChange(false)}
                    >
                        Not Taxable
                    </button>
                </div>
            </div>
        </div>
    }
}

TaxableSwitch.propTypes = {
    isTaxable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
};

export default TaxableSwitch